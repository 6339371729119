
export default {
  name: 'Button',
  props: {
    link: {
      type: String,
      required: true
    },
    isVoid: {
      type: Boolean,
      required: false,
      default: false
    },
    buttonTypeClass: {
      type: String,
      required: false,
      default: 'btn-primary'
    },
    buttonText: {
      type: String,
      required: true
    },
    buttonSizeClass: {
      type: String,
      required: false,
      default: 'btn-md'
    },
    buttonisAnchor: {
      type: Boolean,
      required: false,
      default: false
    },
    isCardButton: {
      type: Boolean,
      required: false,
      default: false
    },
    isContact: {
      type: Boolean,
      required: false,
      default: false
    },
    externalLink: {
      type: Boolean,
      required: false,
      default: false
    },
    withoutArrow: {
      type: Boolean,
      required: false,
      default: false
    },
    byClick: {
      type: Function,
      required: false
    }
  },
  computed: {
    anchorlink() {
      return this.link.replace(/\//g, '')
    },
    normalizedLink: function () {
      let unexpectedRoutes = Array('de', 'en', 'es')
      if (unexpectedRoutes.indexOf(this.link.split('/')[1]) >= 0) {
        let delString =
          unexpectedRoutes[unexpectedRoutes.indexOf(this.link.split('/')[1])]
        return this.link.replace(delString + '/', '')
      }
      return this.link
    }
  }
}
