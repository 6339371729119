
import { SimpleSVG } from 'vue-simple-svg'
export default {
  name: 'Button',
  props: {
    svgFillColor: {
      type: String,
      required: false,
      default: 'rgb(219, 0, 0)'
    },
    linkIcon: {
      type: String,
      required: false,
      default: null
    },
    linkText: {
      type: String,
      required: true
    },
    withoutArrow: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      svgLoaded: false
    }
  },
  components: {
    SimpleSVG
  },
  computed: {
    normalizedLink: function () {
      let unexpectedRoutes = Array('de', 'en', 'es')
      if (unexpectedRoutes.indexOf(this.link.split('/')[1]) >= 0) {
        let delString =
          unexpectedRoutes[unexpectedRoutes.indexOf(this.link.split('/')[1])]
        return this.link.replace(delString + '/', '')
      }
      return this.link
    }
  }
}
