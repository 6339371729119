
import VueMarkdown from 'vue-markdown'
import TrakkenLinkLike from '~/components/ui/TrakkenLinkLike.vue'
import TrakkenButtonNoLink from '~/components/ui/TrakkenButtonNoLink.vue'
import { SimpleSVG } from 'vue-simple-svg'
export default {
  props: {
    isSlider: {
      type: Boolean,
      required: false,
      default: false
    },
    independentIcon: {
      type: Boolean,
      required: false,
      default: false
    },
    centerMode: {
      type: Boolean,
      required: false,
      default: false
    },
    highlightTitle: {
      type: Boolean,
      required: false,
      default: false
    },
    flatMobile: {
      type: Boolean,
      required: false,
      default: false
    },
    noShadow: {
      type: Boolean,
      required: false,
      default: false
    },
    svgFillColor: {
      type: String,
      required: false,
      default: 'rgb(219, 0, 0)'
    },
    svgHoverFillColor: {
      type: String,
      required: false,
      default: 'rgb(219, 0, 0)'
    },
    hoverColor: {
      type: String,
      required: false,
      default: 'transparent'
    },
    linkIcon: {
      type: String,
      required: false,
      default: null
    },
    isButton: {
      type: Boolean,
      required: false,
      default: null
    },
    subtitle: {
      type: String,
      required: false,
      default: null
    },
    title: {
      type: String,
      required: false,
      default: null
    },
    image: {
      type: String,
      required: false,
      default: null
    },
    icon: {
      type: String,
      required: false,
      default: null
    },
    iconClass: {
      type: String,
      required: false,
      default: ''
    },
    infoText: {
      type: String,
      required: false,
      default: null
    },
    linkText: {
      type: String,
      required: false,
      default: null
    },
    link: {
      type: String,
      required: false,
      default: ''
    },
    internalLink: {
      type: String,
      required: false,
      default: null
    },
    externalLink: {
      type: String,
      required: false,
      default: null
    }
  },
  components: {
    VueMarkdown,
    SimpleSVG,
    TrakkenLinkLike,
    TrakkenButtonNoLink
  },
  data() {
    return {
      hoverEffect: false,
      showSimpleSVG01: false,
      showSimpleSVG02: false
    }
  },
  computed: {
    getCardBodyClass() {
      return this.isSlider ? 'card-body d-flex flex-column' : 'card-body'
    },
    // getCardWrapperClass() {
    //   let className = this.isSlider
    //     ? 'trakken-card pb-3 pt-3 h-100'
    //     : 'trakken-card pb-3 pt-3'
    //   return this.hoverEffect ? className + ' hover-effect' : className
    // },
    getImageClass() {
      var extraClass =
        this.icon ||
        this.title ||
        this.subtitle ||
        this.infoText ||
        this.linkText ||
        this.internalLink ||
        this.externalLink
          ? 'card-img-top'
          : 'card-img'
      return extraClass
    },
    getCenteredClass() {
      return this.centerMode ? 'card-text-center' : 'card-text-left'
    },
    getShadowClass() {
      return this.noShadow ? 'no-shadow' : ''
    },
    getHightlightClass() {
      return this.highlightTitle ? 'title-highlight' : ''
    },
    getCardClass() {
      let extraClassName = this.hoverEffect ? 'hover-effect' : ''
      let flatMobile = this.flatMobile ? 'mobile-flat-card' : ''
      let hoverClass =
        this.hoverColor === null ? 'transparent' : this.hoverColor
      let className =
        'card ' +
        this.getCenteredClass +
        ' ' +
        this.getShadowClass +
        ' ' +
        extraClassName +
        ' ' +
        flatMobile +
        ' ' +
        hoverClass
      return className
    },
    getCardStyle() {
      let hoverColor = this.hoverColor ? this.hoverColor : 'transparent'
      let svgHoverFillColor = this.svgHoverFillColor
        ? this.svgHoverFillColor
        : 'rgb(219, 0, 0)'
      return {
        '--bg-color': hoverColor,
        '--svg-hover-color': svgHoverFillColor,
        width: '100%;'
      }
    }
  }
}
